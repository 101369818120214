<template>
  <div class="adminMain">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/proList' }">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type != 'edit' && type != 'look'">新建项目</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 'edit'">编辑项目</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 'look'">查看项目</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="hengBox"></div> -->

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="formMain mT20">
        <div class="formMain-title">
          <img style="margin-right: 12px" src="@/assets/images/fragment-title-icon.png" />
          <div>桥梁基本信息</div>
        </div>
        <div class="formMain-content box center flexW">
          <el-form-item label="桥梁名称" prop="name">
            <el-input :disabled="isDisabled" v-model.trim="ruleForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="线路名称" prop="lineName">
            <el-input :disabled="isDisabled" v-model.trim="ruleForm.lineName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥梁全长" prop="bridgeLength">
            <el-input :disabled="isDisabled" v-model.trim="ruleForm.bridgeLength" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="高速类型" prop="highSpeedType">
            <el-input :disabled="isDisabled" v-model="ruleForm.highSpeedType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥跨组合" prop="bridgeAcross">
            <el-input :disabled="isDisabled" v-model="ruleForm.bridgeAcross" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item class="cityItem selectItem" label="所在地" prop="cityId">
            <el-select :disabled="isDisabled" popper-class="citySel" @change="provinceChange" v-model="ruleForm.provinceId" placeholder="请选择">
              <el-option v-for="item in provinceList" :key="item.name" :label="item.name" :value="item.id" />
            </el-select>
            <el-select :disabled="isDisabled" popper-class="citySel" class="ml24" v-model="ruleForm.cityId" placeholder="请选择">
              <el-option v-for="item in cityList" :key="item.name" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="上部结构" prop="superstructure">
            <el-input :disabled="isDisabled" v-model="ruleForm.superstructure" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="养护桩号" prop="maintainPile">
            <el-input :disabled="isDisabled" v-model="ruleForm.maintainPile" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="下部结构" prop="substruction">
            <el-input v-model="ruleForm.substruction" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥梁性质" prop="nature">
            <el-input :disabled="isDisabled" v-model="ruleForm.nature" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥面宽度" prop="bridgeWidth">
            <el-input :disabled="isDisabled" v-model="ruleForm.bridgeWidth" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="主桥上部结构" prop="mainSuperstructure">
            <el-input :disabled="isDisabled" v-model="ruleForm.mainSuperstructure" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="设计荷载" prop="designLoad">
            <el-input :disabled="isDisabled" v-model="ruleForm.designLoad" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥墩类型" prop="piersType">
            <el-input :disabled="isDisabled" v-model="ruleForm.piersType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="竖曲线半径" prop="radius">
            <el-input :disabled="isDisabled" v-model="ruleForm.radius" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥台类型" prop="abutmentType">
            <el-input :disabled="isDisabled" v-model="ruleForm.abutmentType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥面横坡" prop="crossSlope">
            <el-input :disabled="isDisabled" v-model="ruleForm.crossSlope" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="支座类型" prop="bearingType">
            <el-input :disabled="isDisabled" v-model="ruleForm.bearingType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="抗震等级" prop="antiseismicLevel">
            <el-input :disabled="isDisabled" v-model="ruleForm.antiseismicLevel" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥墩基础类型" prop="piersBaseType">
            <el-input :disabled="isDisabled" v-model="ruleForm.piersBaseType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="设计洪水频率" prop="floodFrequency">
            <el-input :disabled="isDisabled" v-model="ruleForm.floodFrequency" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥台基础类型" prop="abutmentBaseType">
            <el-input :disabled="isDisabled" v-model="ruleForm.abutmentBaseType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="通航等级" prop="navigationLevel">
            <el-input :disabled="isDisabled" v-model="ruleForm.navigationLevel" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="桥面铺装类型" prop="bridgeDeckPavementType">
            <el-input v-model="ruleForm.bridgeDeckPavementType" placeholder="请输入"></el-input>
          </el-form-item>
          <!-- <el-form-item label="" prop="">
              <el-input style="visibility: hidden;" v-model="ruleForm.substruction"></el-input>
            </el-form-item> -->
          <el-form-item label="桥梁类型" class="selectItem" prop="bridgeType">
            <el-select v-model="ruleForm.bridgeType" placeholder="请选择" @change="changeType">
              <el-option v-for="item in options" :key="item.tid" :label="item.tname" :value="item.tid"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="竣工时间" class="jungongTime" prop="completionTime">
            <el-date-picker v-model="ruleForm.completionTime" type="year" format="yyyy" value-format="yyyy" placeholder="请选择年份"> </el-date-picker>
          </el-form-item>
          <el-form-item label="技术状态" class="selectItem" prop="technical">
            <el-select v-model="ruleForm.technical" placeholder="请选择" @change="changeStatus">
              <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="模型角度" prop="">
            <el-input v-model="ruleForm.modelAngles" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="模型地址" prop="">
            <el-input v-model="ruleForm.modelUrl" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="" prop="">
            <el-input style="visibility: hidden"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="formMain mT20">
        <div class="formMain-title">
          <img style="margin-right: 12px" src="@/assets/images/fragment-title-icon.png" />
          <div>其他信息</div>
        </div>
        <div class="formMain-content box center flexW">
          <div class="el-form-item danwei-item">
            <div class="el-form-item__label requiredTip box center horizontal">
              <span>所属单位</span>
            </div>
            <el-form-item prop="departmentId" style="border-top: none">
              <el-cascader
                :disabled="isDisabled"
                v-model="compayArrId"
                :options="companyList"
                :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                  value: 'id',
                  label: 'name',
                }"
                :show-all-levels="false"
                clearable
              ></el-cascader>
            </el-form-item>
          </div>
          <el-form-item label="经度" class="border-item" prop="longitude">
            <el-input :disabled="isDisabled" v-model.trim="ruleForm.longitude" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="纬度" class="border-item" prop="latitude">
            <el-input :disabled="isDisabled" v-model.trim="ruleForm.latitude" placeholder="请输入"></el-input>
          </el-form-item>
          <div class="el-form-item docItemMain img-box" v-if="ruleForm.bridgeType">
            <div class="el-form-item__label box center horizontal">
              <span>系统划分</span>
            </div>

            <el-form-item class="flex1">
              <div class="model_upload" v-if="type == 'add'">
                <div class="tip-text"></div>
                <div class="upload-btn-warp" style="margin-left: 8px; cursor: pointer; margin-right: 30px" @click="showSystemDialog">
                  <img class="shuru-img" src="../../../assets/images/addPro/setting.png" alt="" srcset="" />
                  <span>设置</span>
                </div>
              </div>
              <div class="model_upload" v-if="type == 'edit' || type == 'look'">
                <div class="tip-text" style="color: #fff"></div>
                <!-- <div class="tip-text" v-else>请设置</div> -->
                <div class="upload-btn-warp" style="margin-left: 8px; cursor: pointer; margin-right: 18px; margin-right: 30px" @click="showSystemDialog">
                  <img class="shuru-img" src="../../../assets/images/addPro/setting.png" alt="" srcset="" />
                  <span>设置</span>
                </div>
              </div>
            </el-form-item>
          </div>
          <!-- <el-form-item
            class="docItemMain system-item"
            label="系统划分"
            v-if="ruleForm.bridgeType"
          >
            
          </el-form-item> -->
          <div class="el-form-item docItemMain img-box">
            <div class="el-form-item__label requiredTip box center horizontal">
              <span>桥梁封面</span>
            </div>
            <el-form-item class="flex1" prop="imagesId">
              <el-upload
                class="upload-demo"
                :disabled="isDisabled"
                :headers="{ token }"
                :on-success="onAllViewSuccess"
                :before-upload="beforeAllViewUpload"
                ref="uploadAllView"
                accept=".jpg, .png"
                :action="upBridgeImgUrl"
                :show-file-list="false"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <img v-if="allViewImgSrc" :src="allViewImgSrc" alt="" class="cover-img" />
              <!-- <el-upload
                :class="isDisabled ? 'hideUpload' : ''"
                :disabled="isDisabled"
                :action="upBridgeImgUrl"
                :headers="uploadHeaders"
                multiple
                :limit="limitNum"
                :file-list="bridgeImgList"
                :on-change="handleAddChange"
                :on-exceed="handleExceed"
                list-type="picture-card"
                :on-remove="handleAddRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
            </el-form-item>
          </div>
          <!-- <div class="el-form-item docItemMain">
            <div class="el-form-item__label box center horizontal"><span>识别信息</span></div>
            <el-form-item class="flex1" prop="file_arr1">
              <el-upload
                :class="isDisabled ? 'hideUpload' : ''"
                :disabled="isDisabled"
                :action="upBridgeImgUrl1"
                :headers="uploadHeaders"
                multiple
                :limit="limitNum"
                :file-list="bridgeImgList1"
                :on-change="handleAddChange1"
                :on-exceed="handleExceed1"
                list-type="picture-card"
                :on-remove="handleAddRemove1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </div> -->
          <!-- <div class="el-form-item docItemMain">
            <div class="el-form-item__label box center horizontal"><span>测点布置</span></div>
            <el-form-item class="flex1" prop="file_arr2">
              <el-upload
                :class="isDisabled ? 'hideUpload' : ''"
                :disabled="isDisabled"
                :action="upBridgeImgUrl2"
                :headers="uploadHeaders"
                multiple
                :limit="limitNum"
                :file-list="bridgeImgList2"
                :on-change="handleAddChange2"
                :on-exceed="handleExceed2"
                list-type="picture-card"
                :on-remove="handleAddRemove2"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </div> -->
        </div>
      </div>
      <!-- v-if="type != 'edit' && type != 'look'" -->
      <div class="formMain mT20">
        <div class="formMain-title">
          <img style="margin-right: 12px" src="@/assets/images/fragment-title-icon.png" />
          <div>模型信息</div>
        </div>
        <div class="formMain-content box center flexW">
          <el-form-item label="导入midas模型" v-if="type == 'add'">
            <div class="model_upload">
              <div class="tip-text" v-if="!isUploadMidsa">请导入</div>
              <el-upload
                slot="suffix"
                :headers="uploadHeaders"
                :action="uploadModelUrl"
                :data="{ bizId: projectId }"
                class="upload-demo-model"
                :on-success="uploadMidsaModelSuccess"
                :on-remove="handlerRemoveMidsaModel"
              >
                <div class="upload-btn-warp" v-if="!isUploadMidsa">
                  <img class="upload-img" src="../../../assets/images/addPro/daoru.png" alt="" srcset="" />
                  <span>导入</span>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="导入midas模型" v-if="type == 'edit' || type == 'look'">
            <div class="model_upload">
              <div class="tip-text" v-if="!ruleForm.m_midas_file_name && !isUploadMidsa">请导入</div>
              <div class="name-text" v-if="ruleForm.m_midas_file_name && !isUploadMidsa">
                {{ ruleForm.m_midas_file_name }}
              </div>
              <el-upload
                v-if="type == 'edit'"
                slot="suffix"
                :headers="uploadHeaders"
                :action="uploadModelUrl"
                :data="{ bizId: projectId }"
                class="upload-demo-model"
                :on-success="uploadMidsaModelSuccess"
                :on-remove="handlerRemoveMidsaModel"
              >
                <div class="upload-btn-warp" v-if="!isUploadMidsa">
                  <img class="upload-img" src="../../../assets/images/addPro/daoru.png" alt="" srcset="" />
                  <span>导入</span>
                </div>
              </el-upload>
              <!-- <div
                @click="
                  downFile(ruleForm.m_midas_file_id, ruleForm.m_midas_file_name)
                "
                class="upload-btn-warp"
                style="margin-left: 10px; cursor: pointer"
                v-if="ruleForm.m_midas_file_id"
                :title="ruleForm.m_midas_file_name"
              >
                <img
                  class="upload-img"
                  src="../../../assets/images/addPro/daoru.png"
                  alt=""
                  srcset=""
                />
                <span>下载</span>
              </div> -->
            </div>
          </el-form-item>
          <el-form-item label="模型单位" prop="m_unit">
            <el-select v-model="ruleForm.m_unit" placeholder="请选择">
              <el-option v-for="item in unitOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="截面尺寸数据" v-if="type == 'add'">
            <div class="model_upload">
              <div class="tip-text" v-if="!isUploadSizeModel">请导入</div>
              <el-upload
                slot="suffix"
                :headers="uploadHeaders"
                :action="uploadModelUrl"
                :data="{ bizId: projectId }"
                class="upload-demo-model"
                :on-success="uploadSizeModelSuccess"
                :on-remove="handlerRemoveSizeModel"
              >
                <div class="upload-btn-warp" v-if="!isUploadSizeModel">
                  <img class="upload-img" src="../../../assets/images/addPro/daoru.png" alt="" srcset="" />
                  <span>导入</span>
                </div>
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item label="截面尺寸数据" v-if="type == 'edit' || type == 'look'">
            <div class="model_upload">
              <div class="tip-text" v-if="!ruleForm.m_size_file_name && !isUploadSizeModel">请导入</div>
              <div class="name-text" v-if="ruleForm.m_size_file_name && !isUploadSizeModel">
                {{ ruleForm.m_size_file_name }}
              </div>
              <el-upload
                slot="suffix"
                :headers="uploadHeaders"
                :action="uploadModelUrl"
                :data="{ bizId: projectId }"
                class="upload-demo-model"
                :on-success="uploadSizeModelSuccess"
                :on-remove="handlerRemoveSizeModel"
                v-if="type == 'edit'"
              >
                <div class="upload-btn-warp" v-if="!isUploadSizeModel">
                  <img class="upload-img" src="../../../assets/images/addPro/daoru.png" alt="" srcset="" />
                  <span>导入</span>
                </div>
              </el-upload>
              <!-- <div
                @click="
                  downFile(ruleForm.m_size_file_id, ruleForm.m_size_file_name)
                "
                class="upload-btn-warp"
                style="margin-left: 10px; cursor: pointer"
                v-if="ruleForm.m_size_file_id"
                :title="ruleForm.m_size_file_name"
              >
                <img
                  class="upload-img"
                  src="../../../assets/images/addPro/daoru.png"
                  alt=""
                  srcset=""
                />
                <span>下载</span>
              </div> -->
            </div>
          </el-form-item>

          <el-form-item label="材料强度数据" v-if="type == 'add'">
            <div class="model_upload">
              <div class="tip-text" v-if="!isUploadStrengthModel">请导入</div>
              <el-upload
                slot="suffix"
                :headers="uploadHeaders"
                :action="uploadModelUrl"
                :data="{ bizId: projectId }"
                class="upload-demo-model"
                :on-success="uploadStrengthModelSuccess"
                :on-remove="handlerRemoveStrengthModel"
              >
                <div class="upload-btn-warp" v-if="!isUploadStrengthModel">
                  <img class="upload-img" src="../../../assets/images/addPro/daoru.png" alt="" srcset="" />
                  <span>导入</span>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="材料强度数据" v-if="type == 'edit' || type == 'look'">
            <div class="model_upload">
              <div class="tip-text" v-if="!ruleForm.m_strength_file_name && !isUploadStrengthModel">请导入</div>
              <div class="name-text" v-if="ruleForm.m_strength_file_name && !isUploadStrengthModel">
                {{ ruleForm.m_strength_file_name }}
              </div>
              <el-upload
                slot="suffix"
                :headers="uploadHeaders"
                :action="uploadModelUrl"
                :data="{ bizId: projectId }"
                class="upload-demo-model"
                :on-success="uploadStrengthModelSuccess"
                :on-remove="handlerRemoveStrengthModel"
                v-if="type == 'edit'"
              >
                <div class="upload-btn-warp" v-if="!isUploadStrengthModel">
                  <img class="upload-img" src="../../../assets/images/addPro/daoru.png" alt="" srcset="" />
                  <span>导入</span>
                </div>
              </el-upload>
              <!-- <div
                @click="
                  downFile(
                    ruleForm.m_strength_file_id,
                    ruleForm.m_strength_file_name
                  )
                "
                class="upload-btn-warp"
                style="margin-left: 10px; cursor: pointer"
                v-if="ruleForm.m_strength_file_id"
                :title="ruleForm.m_strength_file_name"
              >
                <img
                  class="upload-img"
                  src="../../../assets/images/addPro/daoru.png"
                  alt=""
                  srcset=""
                />
                <span>下载</span>
              </div> -->
            </div>
          </el-form-item>

          <el-form-item label="桥墩个数" class="border-item" prop="">
            <el-input
              v-model="ruleForm.m_pier_value"
              placeholder="请输入"
              type="number"
              min="0"
              max="999"
              onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"
              @change="pierValueChange"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否区分左右幅" class="border-item">
            <el-select v-model="ruleForm.m_around_status" placeholder="请选择" @change="changeStatus">
              <el-option v-for="item in aroundStatusList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="绑定关键点">
            <div class="model_upload" v-if="type == 'add'">
              <div class="tip-text">请设置</div>
              <div class="upload-btn-warp" style="margin-left: 8px; cursor: pointer; margin-right: 30px" @click="showPonitDialog">
                <img class="shuru-img" src="../../../assets/images/addPro/setting.png" alt="" srcset="" />
                <span>设置</span>
              </div>
            </div>
            <div class="model_upload" v-if="type == 'edit' || type == 'look'">
              <div class="tip-text" style="color: #fff" v-if="ruleForm.m_list.length">已设置</div>
              <div class="tip-text" v-else>请设置</div>
              <div class="upload-btn-warp" style="margin-left: 8px; cursor: pointer; margin-right: 18px; margin-right: 30px" @click="showPonitDialog">
                <img class="shuru-img" src="../../../assets/images/addPro/setting.png" alt="" srcset="" />
                <span>设置</span>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div v-if="!isDisabled" class="box center horizontal mT50 mB10">
      <!-- <el-button @click="save">保存</el-button> -->
      <!-- <el-button class="restBtn" @click="returnPage">返回</el-button> -->
      <div class="btnSelect1 mR20 pointer" @click="returnPage">返回</div>
      <div class="btnSelect2 pointer" @click="save">保存</div>
    </div>
    <div v-if="isDisabled" class="box center horizontal mT50 mB10">
      <div class="btnSelect1 pointer" @click="returnPage">返回</div>
    </div>
    <div style="height: 20px"></div>

    <!-- 关键点 -->
    <el-dialog title="节点号" v-if="isShowPointDialog" :visible.sync="isShowPointDialog" class="my-dialog add-dialog" top="7vh" :before-close="handlePointDialogClose">
      <div class="report-wrapper">
        <table class="report-table">
          <tr>
            <th colspan="3">墩位</th>
            <th colspan="3">测点位</th>
            <th colspan="3">节点号<span v-if="ruleForm.m_around_status != 0">左</span></th>
            <th colspan="3" v-if="ruleForm.m_around_status != 0">节点号右</th>
          </tr>
          <template v-for="(item, index) in pointList">
            <tr :key="'tr' + index">
              <td :rowspan="item.cdList.length" colspan="3">
                {{ item.dwName }}
              </td>
              <td colspan="3">
                {{ item.cdList[0].name }}
              </td>
              <td colspan="3">
                <el-input v-model="item.cdList[0].leftValue" placeholder="请输入" :readonly="!isEditPoint" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
              </td>
              <td colspan="3" v-if="ruleForm.m_around_status != 0">
                <el-input v-model="item.cdList[0].rightValue" placeholder="请输入" :readonly="!isEditPoint" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
              </td>
            </tr>
            <template v-for="(cdItem, cdIndex) in item.cdList">
              <tr :key="'td' + index + '' + cdIndex" v-if="cdIndex != 0">
                <td colspan="3">{{ cdItem.name }}</td>
                <td colspan="3">
                  <el-input v-model="item.cdList[cdIndex].leftValue" placeholder="请输入" :readonly="!isEditPoint" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
                </td>
                <td colspan="3" v-if="ruleForm.m_around_status != 0">
                  <el-input v-model="item.cdList[cdIndex].rightValue" placeholder="请输入" :readonly="!isEditPoint" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
                </td>
              </tr>
            </template>
          </template>
        </table>
        <div class="btn-footer" v-if="isEditPoint">
          <el-button @click="handlePointDialogSave">保存</el-button>
          <el-button class="cancel-btn" @click="handlePointDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 关键点 -->
    <el-dialog title="系统划分" v-if="isShowSystemDialog" :visible.sync="isShowSystemDialog" class="my-dialog add-dialog" top="7vh" :before-close="handleSystemDialogClose">
      <div class="report-wrapper">
        <table class="report-table">
          <tr>
            <th colspan="3">一级系统</th>
            <th colspan="3">二级系统</th>
            <th colspan="3" v-if="type != 'look'">操作</th>
          </tr>
          <template v-for="(item, index) in systemList">
            <tr :key="'tr' + item.name + item.id">
              <td colspan="3" style="text-align: center">{{ item.name }}</td>
              <td colspan="3" style="text-align: center">/</td>
              <td colspan="3" style="cursor: pointer; color: #32c5ff; text-align: center" @click="addSystemItem(index)" v-if="type != 'look'">新增</td>
            </tr>
            <template v-for="(citem, cindex) in item.children">
              <tr :key="'ctr' + item.name + cindex">
                <td colspan="3" style="text-align: center">{{ item.name }}</td>
                <td colspan="3" style="text-align: center">
                  <el-input
                    v-if="citem.isInput"
                    :key="'cinput' + item.name + cindex"
                    v-model="item.children[cindex].name"
                    placeholder="请输入"
                    @input="handleSystemInput(index, cindex, $event)"
                  ></el-input>
                  <!-- @change="handleSystemChange(index, cindex, $event)" -->

                  <span v-else @dblclick="handlerEditSystem(index, cindex)">{{ citem.name }}</span>
                </td>
                <td colspan="3" style="cursor: pointer; color: #32c5ff; text-align: center" @click="handleSystemDelete(index, cindex, citem.isInput)" v-if="type != 'look'">
                  {{ citem.isInput ? "取消" : "删除" }}
                </td>
              </tr>
            </template>
          </template>

          <!-- <template v-for="(item, index) in pointList">
            <tr :key="'tr' + index">
              <td :rowspan="item.cdList.length" colspan="3">
                {{ item.dwName }}
              </td>
              <td colspan="3">
                {{ item.cdList[0].name }}
              </td>
              <td colspan="3">
                <el-input
                  v-model="item.cdList[0].leftValue"
                  placeholder="请输入"
                  :readonly="!isEditPoint"
                  onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"
                ></el-input>
              </td>
              <td colspan="3" v-if="ruleForm.m_around_status != 0">
                <el-input
                  v-model="item.cdList[0].rightValue"
                  placeholder="请输入"
                  :readonly="!isEditPoint"
                  onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"
                ></el-input>
              </td>
            </tr>
            <template v-for="(cdItem, cdIndex) in item.cdList">
              <tr :key="'td' + index + '' + cdIndex" v-if="cdIndex != 0">
                <td colspan="3">{{ cdItem.name }}</td>
                <td colspan="3">
                  <el-input
                    v-model="item.cdList[cdIndex].leftValue"
                    placeholder="请输入"
                    :readonly="!isEditPoint"
                    onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"
                  ></el-input>
                </td>
                <td colspan="3" v-if="ruleForm.m_around_status != 0">
                  <el-input
                    v-model="item.cdList[cdIndex].rightValue"
                    placeholder="请输入"
                    :readonly="!isEditPoint"
                    onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"
                  ></el-input>
                </td>
              </tr>
            </template>
          </template> -->
        </table>
        <div class="btn-footer" v-if="isEditPoint">
          <el-button @click="handleSystemDialogCirm">确定</el-button>
          <!-- <el-button class="cancel-btn" @click="handleSystemDialogClose"
            >取消</el-button
          > -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import beforeDestory from "../../beforeDestory";

export default {
  mixins: [beforeDestory],
  data() {
    var checkData = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback("不能输入中文");
        } else {
          callback();
        }
      }
      callback();
    };

    return {
      rules: {
        emptyNum: "",
        emptyNum1: "",
        name: [
          { required: true, message: "请输入桥梁名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在1到50个字符之间",
            trigger: "blur",
          },
        ],
        lineName: [{ required: true, message: "请输入线路名称", trigger: "blur" }],
        bridgeLength: [
          { required: true, message: "请输入桥梁全长", trigger: "blur" },
          { validator: checkData, trigger: "blur" },
        ],
        cityId: [{ required: true, message: "请选择所在地", trigger: "change" }],
        bridgeType: [{ required: true, message: "请选择桥型", trigger: "change" }],
        technical: [{ required: true, message: "请选择技术状态", trigger: "change" }],

        departmentId: [{ required: true, message: "请选择所属单位", trigger: "blur" }],
        longitude: [{ required: true, message: "请输入经度", trigger: "blur" }],
        latitude: [{ required: true, message: "请输入纬度", trigger: "blur" }],
        imagesId: [{ required: true, message: "请选择桥梁封面", trigger: "blur" }],
      },

      allViewImgSrc: "",
      allViewImgId: "",

      midsaFileList: [],
      isShowPointDialog: false,
      isShowSystemDialog: false,
      isEditPoint: true,
      pointList: [],
      systemInputList: [],

      ruleForm: {
        bridgeType: "", //桥梁类型
        technical: "", //技术等级
        modelAngles: "", //模型角度
        modelUrl: "", //模型地址
        name: "",
        departmentId: "",
        provinceId: "",
        bridgeLength: "",
        longitude: "",
        cityId: "",
        lineName: "",
        highSpeedType: "",
        maintainPile: "",
        nature: "",
        mainSuperstructure: "",
        piersType: "",
        abutmentType: "",
        bearingType: "",
        piersBaseType: "",
        abutmentBaseType: "",
        bridgeDeckPavementType: "",
        completionTime: "",
        latitude: "",
        imagesId: "",
        bridgeAcross: "",
        superstructure: "",
        bridgeWidth: "",
        designLoad: "",
        radius: "",
        crossSlope: "",
        antiseismicLevel: "",
        floodFrequency: "",
        navigationLevel: "",
        substruction: "",

        m_midas_file_id: "",
        m_size_file_id: "",
        m_strength_file_id: "",

        m_pier_value: 0,

        m_around_status: 0,
        m_unit: 1,
      },
      m_list: [],

      compayArrId: [],
      companyList: [],
      checkList: [
        { id: 1, name: "超级管理员" },
        { id: 2, name: "一般管理员" },
      ],
      aroundStatusList: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      checkedList: [],
      provinceList: [],
      cityList: [],
      bridgeImgList: [],
      bridgeImgList1: [],
      bridgeImgList2: [],
      upBridgeImgUrl: "",
      upBridgeImgUrl1: "",
      upBridgeImgUrl2: "",
      uploadModelUrl: "",
      uploadHeaders: {},
      limitNum: 10,
      type: "",
      id: "",
      showImgUrl: ["cover", "identification", "layout"],
      isDisabled: false,

      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),

      options: [], //桥梁类型列表
      options1: [
        { id: 1, name: "在建" },
        { id: 2, name: "I" },
        { id: 3, name: "II" },
        { id: 4, name: "III" },
        { id: 5, name: "IV" },
      ],
      //模型单位
      unitOptions: [
        { id: 1, name: "毫米" },
        { id: 2, name: "米" },
      ],
      isUploadMidsa: false,
      isUploadSizeModel: false,
      isUploadStrengthModel: false,
      tempSystemList: [],
      m_system_list:[]
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.qiaotype(); //桥梁类型
    this.getCompanyList();
    this.upBridgeImgUrl = this.baseURL + "sys/sysImages/save/cover";
    this.upBridgeImgUrl1 = this.baseURL + "sys/sysImages/save/identification";
    this.upBridgeImgUrl2 = this.baseURL + "sys/sysImages/save/layout";
    this.uploadModelUrl = this.baseURL + "sys/sysFile/model/import";
    this.uploadHeaders = { token: window.sessionStorage.getItem("token") };

    if (this.type != "edit" && this.type != "look") {
      let arr = this.$store.state.breadcrumbList;
      arr.push({ name: "新建项目" });
      this.$store.commit("getBreadcrumb", arr);
    } else if (this.type == "edit") {
      let arr = this.$store.state.breadcrumbList;
      arr.push({ name: "修改项目" });
      this.$store.commit("getBreadcrumb", arr);
    } else {
      let arr = this.$store.state.breadcrumbList;
      arr.push({ name: "查看项目" });
      this.$store.commit("getBreadcrumb", arr);
    }

    if (this.id && this.type !== "add") {
      this.getProvinceList().then(() => {
        this.getBridgeDetail().then(() => {
          if (!this.ruleForm.provinceId) return;
          this.getCityList();
        });
      });

      for (let i = 0; i < 3; i++) {
        this.getBridgeImg(i);
      }
      if (this.type === "look") this.isDisabled = true;
    } else {
      this.getProvinceList();
    }
  },

  methods: {
    handlerEditSystem(index, cindex) {
      this.systemList[index].children[cindex].isInput = true;
      this.$forceUpdate();
    },
    handleSystemDelete(index, cindex, isInput) {
      if (isInput) {
        this.systemList[index].children.splice(cindex, 1);
        this.$forceUpdate();
      } else {
        var item = this.systemList[index].children[cindex];
        var params = {
          id: item.id,
          name: item.name,
          pid: item.pid,
          projectId: this.projectId,
          type: item.type,
        };
        this.$axios.post(`${this.baseURL}health/system/deleteSecondSystem`, this.$qs.stringify(params)).then((res) => {
          if (res.data.errCode == 200) {
            this.systemList[index].children.splice(cindex, 1);
            // this.getSelectSecondSystemByBridgeType()
            this.$forceUpdate();
          }
        });
      }
    },
    handleSystemInput(index, cindex, value) {
      this.systemList[index].children[cindex].name = value;
      this.$forceUpdate();
    },
    handleSystemChange(index, cindex) {
      var item = this.systemList[index].children[cindex];
      var params = {
        id: item.id || "",
        name: item.name,
        pid: item.pid,
        projectId: this.$route.query.id,
        type: item.type,
      };
      this.$axios.post(`${this.baseURL}health/system/saveSecondSystem`, this.$qs.stringify(params)).then((res) => {
        if (res.data.errCode == 200) {
          item.isInput = false;
          this.getSelectSecondSystemByBridgeType();
          this.$forceUpdate();
        }
      });
    },
    addSystemItem(index) {
      if (!this.systemList[index].children) {
        this.$set(this.systemList[index], "children", []);
      }
      this.$set(this.systemList[index].children, this.systemList[index].children.length, {
        pid: this.systemList[index].id,
        type: 3,
        name: "",
        isInput: true,
      });
      this.$forceUpdate();
    },
    showSystemDialog() {
      this.isShowSystemDialog = true;
    },
    handleSystemDialogClose() {
      this.isShowSystemDialog = false;
    },
    handleSystemDialogCirm() {
      var m_system_list = [];
      this.systemList.forEach((item) => {
        m_system_list.push({
          id: item.id || "",
          name: item.name || "",
          pid: item.pid,
        });
        if (item.children) {
          item.children.forEach((item2) => {
            if (item2.name) {
              m_system_list.push({
                id: item2.id || "",
                name: item2.name || "",
                pid: item2.pid,
              });
            }
          });
        }
      });
      this.m_system_list = m_system_list
      this.isShowSystemDialog = false;
    },
    // 获取封面
    getCoverImg() {
      this.$axios.get(`${this.baseURL}sys/sysImages/cover/${this.id}`).then((res) => {
        console.log("获取桥梁封面--图片id", res);
        let detail = res.data.data;
        if (detail[0].id) {
          let imgId = detail[0].id;
          this.allViewImgId = imgId;
          this.allViewImgSrc = `${this.baseURL}sys/sysImages/img/${imgId}`;
          this.ruleForm.imagesId = imgId;
        }
      });
    },
    // 总览背景图上传前
    beforeAllViewUpload(file) {
      console.log(file.type);
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
      }
      return isCorrectType;
    },
    // 上传总览背景图
    onAllViewSuccess(res, file, fileList) {
      console.log(res);
      if (res.status == 200) {
        this.imagesId = res.data;
        this.allViewImgSrc = `${this.baseURL}sys/sysImages/img/${res.data}`;
        this.allViewImgId = res.data;
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.errMsg);
      }
    },

    // 获取桥型下拉
    qiaotype() {
      const params = this.ruleForm;
      this.$axios.post(`${this.baseURL}pandect/select/bridgetype/list`).then((res) => {
        console.log("res-桥梁类型", res);
        this.options = res.data.data;
        res = res.data;
      });
    },
    changeType() {
      this.getSelectSecondSystemByBridgeType();
    },
    getSelectSecondSystemByBridgeType() {
      var params = {
        bridgeType: this.ruleForm.bridgeType,
        projectId: this.type == "add" ? "" : this.$route.query.id,
      };
      this.$axios
        .get(`${this.baseURL}health/system/selectSecondSystemByBridgeType`, {
          params,
        })
        .then((res) => {
          if (res.data.errCode == 200) {
            this.systemList = res.data.data;
            this.tempSystemList = res.data.data;
            this.$forceUpdate();
          } else {
            this.$message.error(res.data.errMsg);
          }
        });
    },
    changeStatus() {},

    // 上传change事件
    handleAddChange(file, fileList) {
      this.bridgeImgList = fileList;
      console.log("bridgeImgList", this.bridgeImgList);
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning",
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.handleAddChange(file, fileList);
    },
    // 上传change事件
    handleAddChange1(file, fileList) {
      this.bridgeImgList1 = fileList;
    },
    // 多选大于限制个数时做提示
    handleExceed1(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning",
      });
    },
    // 移除文件
    handleAddRemove1(file, fileList) {
      this.handleAddChange1(file, fileList);
    },
    // 上传change事件
    handleAddChange2(file, fileList) {
      this.bridgeImgList2 = fileList;
    },
    // 多选大于限制个数时做提示
    handleExceed2(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning",
      });
    },
    // 移除文件
    handleAddRemove2(file, fileList) {
      this.handleAddChange2(file, fileList);
    },
    provinceChange(val) {
      this.ruleForm.provinceId = val;
      this.getCityList().then(() => {
        this.ruleForm.cityId = this.cityList[0].id;
      });
    },
    save() {
      this.rules.departmentId[0].required = this.compayArrId.length > 0 ? false : true;
      this.rules.imagesId[0].required = this.imagesId ? false : true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let list = [];
          let ids = "";
          list = list.concat(this.bridgeImgList);
          list = list.concat(this.bridgeImgList1);
          list = list.concat(this.bridgeImgList2);
          for (let item of list) {
            ids += item.response.data + ",";
          }
          // this.ruleForm.imagesId = ids.substring(0, ids.length - 1);
          this.ruleForm.imagesId = this.imagesId;
          let companyArr = this.compayArrId;
          let str = "";
          for (let item of companyArr) {
            str += item + ",";
          }
          this.ruleForm.departmentId = str.substring(0, str.length - 1);

          if (this.type === "add") {
            this.httpAddPro();
          } else {
            this.httpEditPro();
          }
        }
      });
    },
    clearData() {
      this.bridgeImgList = [];
      this.bridgeImgList1 = [];
      this.bridgeImgList2 = [];
      this.compayArrId = [];
    },
    returnPage() {
      this.clearData();
      this.$router.go(-1);
      this.$refs.ruleForm.resetFields();
    },
    httpAddPro() {
      this.ruleForm.bridgeLength = Number(this.ruleForm.bridgeLength); //转为数字格式
      const params = this.ruleForm;
      // console.log('params 添加桥梁内容',params);
      var m_list = this.m_list;
      var result = "";
      for (var i = 0; i < m_list.length; i++) {
        for (var key in m_list[i]) {
          if (key == "pointRight") {
            if (this.ruleForm.m_around_status != 0) {
              result += `m_list[${i}].${key}=${m_list[i][key]}&`;
            } else {
              result += `m_list[${i}].${key}=&`;
            }
          } else {
            result += `m_list[${i}].${key}=${m_list[i][key]}&`;
          }
        }
      }
      result = result.slice(0, -1);

      var m_system_list = this.m_system_list;
      var result1 = "";
      for (var i = 0; i < m_system_list.length; i++) {
        for (var key in m_system_list[i]) {
          if (key == "pointRight") {
            if (this.ruleForm.m_around_status != 0) {
              result1 += `m_system_list[${i}].${key}=${m_system_list[i][key]}&`;
            } else {
              result1 += `m_system_list[${i}].${key}=&`;
            }
          } else {
            result1 += `m_system_list[${i}].${key}=${m_system_list[i][key]}&`;
          }
        }
      }
      result1 = result1.slice(0, -1);

      this.$axios.post(`${this.baseURL}base/baseProject/`, this.$qs.stringify(params) + "&" + result+"&" + result1).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          /*this.$message({
            message: '保存成功',
            type: 'success'
          });*/
          this.returnPage();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    httpEditPro() {
      var params = this.ruleForm;
      var m_list = this.m_list;
      var result = "";
      for (var i = 0; i < m_list.length; i++) {
        for (var key in m_list[i]) {
          if (key == "pointRight") {
            if (this.ruleForm.m_around_status != 0) {
              result += `m_list[${i}].${key}=${m_list[i][key]}&`;
            } else {
              result += `m_list[${i}].${key}=&`;
            }
          } else {
            result += `m_list[${i}].${key}=${m_list[i][key]}&`;
          }
        }
      }
      result = result.slice(0, -1);

      var m_system_list = this.m_system_list;
      var result1 = "";
      for (var i = 0; i < m_system_list.length; i++) {
        for (var key in m_system_list[i]) {
          if (key == "pointRight") {
            if (this.ruleForm.m_around_status != 0) {
              result1 += `m_system_list[${i}].${key}=${m_system_list[i][key]}&`;
            } else {
              result1 += `m_system_list[${i}].${key}=&`;
            }
          } else {
            result1 += `m_system_list[${i}].${key}=${m_system_list[i][key]}&`;
          }
        }
      }
      result1 = result1.slice(0, -1);

      delete params.m_list;
      this.$axios.put(`${this.baseURL}base/baseProject/`, this.$qs.stringify(params) + "&" + result+"&" + result1).then((res) => {
        console.log("编辑桥梁", res);

        res = res.data;
        if (res.errCode == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.returnPage();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 获取桥梁图片信息
    getBridgeImg(index) {
      this.$axios.get(`${this.baseURL}sys/sysImages/${this.showImgUrl[index]}/${this.id}`).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          let arr = res.data;
          let imgList = [];
          for (let item of arr) {
            let url = `${this.baseURL}sys/sysImages/img/${item.id}`;
            let obj = {
              id: item.id,
              response: { data: item.id },
              url,
            };
            imgList.push(obj);
          }
          switch (index) {
            case 0:
              this.bridgeImgList = imgList;
              break;
            case 1:
              this.bridgeImgList1 = imgList;
              break;
            case 2:
              this.bridgeImgList2 = imgList;
              break;
          }
        }
      });
    },
    // 获取桥梁信息
    getBridgeDetail() {
      return new Promise((resolve) => {
        this.$axios.get(`${this.baseURL}base/baseProject/${this.id}`).then((res) => {
          console.log("res-获取桥梁信息", res);

          res = res.data;
          if (res.errCode === "200") {
            this.ruleForm = { ...res.data };
            this.ruleForm.departmentId = "";
            this.compayArrId = !res.data.departmentId ? [] : res.data.departmentId.split(",");
            this.m_list = this.ruleForm.m_list;
            this.getCoverImg();
            if (this.ruleForm.bridgeType) {
              this.getSelectSecondSystemByBridgeType();
            }

            var mList = this.ruleForm.m_list;
            var pointList = mList.reduce(function (result, current) {
              // 查找是否已存在该 dwName（pierSite）的对象
              var dwObject = result.find(function (dw) {
                return dw.dwName === current.pierSite;
              });
              // 如果不存在，创建一个新的 dwObject
              if (!dwObject) {
                dwObject = {
                  dwName: current.pierSite,
                  cdList: [],
                };
                result.push(dwObject);
              }
              // 将当前元素的信息添加到 cdList 中
              dwObject.cdList.push({
                name: current.pointSite,
                leftValue: current.pointLeft == "null" ? "" : current.pointLeft,
                rightValue: current.pointRight == "null" ? "" : current.pointRight,
              });
              return result;
            }, []);
            this.pointList = pointList;

            resolve();
          }
        });
      });
    },
    // 获取单位
    getCompanyList() {
      this.$axios.get(`${this.baseURL}base/baseDepartment/user/department`).then((res) => {
        res = res.data;
        if (res.errCode === "200") this.companyList = res.data[0].children;
      });
    },
    // 获取省份
    getProvinceList() {
      return new Promise((resolve) => {
        this.$axios.get(`${this.baseURL}base/baseRegionalProvince/all`).then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            this.provinceList = res.data;
            // if (this.type === 'add') this.ruleForm.provinceId = res.data[0].id
            resolve();
          }
        });
      });
    },
    getCityList() {
      return new Promise((resolve) => {
        this.$axios.get(`${this.baseURL}base/baseRegionalCity/${this.ruleForm.provinceId}`).then((res) => {
          res = res.data;
          if (res.errCode === "200") {
            this.cityList = res.data;
            // if (this.type === 'add') this.ruleForm.cityId = res.data[0].id
            resolve();
          }
        });
      });
    },
    //模型上传回调
    uploadMidsaModelSuccess(response, file, fileList) {
      console.log("uploadMidsaModelSuccess", file);
      this.ruleForm.m_midas_file_id = response.data;
      this.ruleForm.m_midas_file_name = file.raw.name;
      this.isUploadMidsa = true;
    },
    uploadSizeModelSuccess(response, file, fileList) {
      this.ruleForm.m_size_file_id = response.data;
      this.ruleForm.m_size_file_name = file.raw.name;

      this.isUploadSizeModel = true;
    },
    uploadStrengthModelSuccess(response, file, fileList) {
      this.ruleForm.m_strength_file_id = response.data;
      this.ruleForm.m_strength_file_name = file.raw.name;
      this.isUploadStrengthModel = true;
    },
    handlerRemoveMidsaModel(file, fileList) {
      this.ruleForm.m_midas_file_id = "";
      this.isUploadMidsa = false;
    },
    handlerRemoveSizeModel(file, fileList) {
      this.ruleForm.m_size_file_id = "";
      this.isUploadSizeModel = false;
    },
    handlerRemoveStrengthModel(file, fileList) {
      this.ruleForm.m_strength_file_id = "";
      this.isUploadStrengthModel = false;
    },
    handlePointDialogClose() {
      this.isShowPointDialog = false;
      const tempPointList = [...this.tempPointList];
      this.pointList = tempPointList;
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    showPonitDialog() {
      const tempPointList = this.deepCopy(this.pointList);
      this.tempPointList = tempPointList;
      if (!this.pointList.length) {
        this.pointList.push({
          dwName: "小里程边跨",
          cdList: [
            {
              name: "梁端",
              leftValue: "",
              rightValue: "",
            },
            {
              name: "梁中部",
              leftValue: "",
              rightValue: "",
            },
          ],
        });
        for (var i = 1; i <= this.ruleForm.m_pier_value; i++) {
          this.pointList.push({
            dwName: "桥墩墩位" + i,
            cdList: [
              {
                name: "梁支座",
                leftValue: "",
                rightValue: "",
              },
            ],
          });
          if (i < this.ruleForm.m_pier_value) {
            this.pointList.push({
              dwName: "墩位" + i + " - " + "墩位" + (i + 1),
              cdList: [
                {
                  name: "1/4跨",
                  leftValue: "",
                  rightValue: "",
                },
                {
                  name: "跨中",
                  leftValue: "",
                  rightValue: "",
                },
                {
                  name: "3/4跨",
                  leftValue: "",
                  rightValue: "",
                },
              ],
            });
          }
        }

        this.pointList.push({
          dwName: "大里程边跨",
          cdList: [
            {
              name: "梁中部",
              leftValue: "",
              rightValue: "",
            },
            {
              name: "梁端",
              leftValue: "",
              rightValue: "",
            },
          ],
        });
      }

      this.isShowPointDialog = true;
    },
    showEditPonitDialog(isEdit) {
      const tempPointList = this.deepCopy(this.pointList);
      this.tempPointList = tempPointList;
      if (!this.pointList.length) {
        var mList = this.ruleForm.m_list;
        var pointList = mList.reduce(function (result, current) {
          // 查找是否已存在该 dwName（pierSite）的对象
          var dwObject = result.find(function (dw) {
            return dw.dwName === current.pierSite;
          });
          // 如果不存在，创建一个新的 dwObject
          if (!dwObject) {
            dwObject = {
              dwName: current.pierSite,
              cdList: [],
            };
            result.push(dwObject);
          }
          // 将当前元素的信息添加到 cdList 中
          dwObject.cdList.push({
            name: current.pointSite,
            leftValue: current.pointLeft,
            rightValue: current.pointRight,
          });
          return result;
        }, []);

        this.pointList = pointList;
      }
      this.isShowPointDialog = true;
      this.isEditPoint = isEdit;
    },
    handlePointDialogSave() {
      var postPointList = [];
      for (var i = 0; i < this.pointList.length; i++) {
        var dwName = this.pointList[i].dwName;
        var cdList = this.pointList[i].cdList;
        for (var j = 0; j < cdList.length; j++) {
          var pointItem = {};
          pointItem.pierSite = dwName;
          pointItem.pointSite = cdList[j].name;
          pointItem.pointLeft = cdList[j].leftValue;
          if (this.ruleForm.m_around_status != 0) {
            pointItem.pointRight = cdList[j].rightValue;
          } else {
            pointItem.pointRight = "";
          }
          postPointList.push(pointItem);
        }
      }
      this.m_list = postPointList;
      this.isShowPointDialog = false;
    },
    downFile(id, name) {
      this.$axios.get(`${this.baseURL}sys/sysFile/${id}`).then((res) => {
        var responseData = res.data;
        var blob = new Blob([responseData], { type: "text/plain" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    pierValueChange(e) {
      if (!this.pointList.length) {
        return;
      }
      // 保存原始数据
      const originalPointList = this.deepCopy(this.pointList);
      // 清空原数组
      this.pointList.length = 0;

      // 重新生成数组
      this.pointList.push({
        dwName: "小里程边跨",
        cdList: [
          {
            name: "梁端",
            leftValue: "",
            rightValue: "",
          },
          {
            name: "梁中部",
            leftValue: "",
            rightValue: "",
          },
        ],
      });

      for (let i = 1; i <= this.ruleForm.m_pier_value; i++) {
        this.pointList.push({
          dwName: "桥墩墩位" + i,
          cdList: [
            {
              name: "梁支座",
              leftValue: "",
              rightValue: "",
            },
          ],
        });
        if (i < this.ruleForm.m_pier_value) {
          this.pointList.push({
            dwName: "墩位" + i + " - " + "墩位" + (i + 1),
            cdList: [
              {
                name: "1/4跨",
                leftValue: "",
                rightValue: "",
              },
              {
                name: "跨中",
                leftValue: "",
                rightValue: "",
              },
              {
                name: "3/4跨",
                leftValue: "",
                rightValue: "",
              },
            ],
          });
        }
      }

      this.pointList.push({
        dwName: "大里程边跨",
        cdList: [
          {
            name: "梁中部",
            leftValue: "",
            rightValue: "",
          },
          {
            name: "梁端",
            leftValue: "",
            rightValue: "",
          },
        ],
      });
      // 根据原始数据更新新生成的数组
      for (const originalItem of originalPointList) {
        const newItem = this.pointList.find((item) => item.dwName === originalItem.dwName);

        if (newItem) {
          for (let i = 0; i < originalItem.cdList.length; i++) {
            newItem.cdList[i].leftValue = originalItem.cdList[i].leftValue;
            newItem.cdList[i].rightValue = originalItem.cdList[i].rightValue;
          }
        }
      }
      this.handlePointDialogSave();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form .formMain .el-form-item.flex1 .el-form-item__content {
  justify-content: start !important;
  .cover-img {
    height: 42px;
  }
}
.upload-demo {
  width: 70px !important;
  height: 50px;
  line-height: 50px;
  margin: 0 20px;
  text-align: center;
  background: #192c52;
  &:hover {
    cursor: pointer;
  }
  ::v-deep .el-upload.el-upload--text {
    width: 100%;
    height: 100%;
    i {
      color: #3d6391;
    }
  }
}
::v-deep .el-form {
  .formMain {
    // border: 1px solid #5E5E5F;
    // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
    background: #0a183f;
    border: 1px solid #0c3380;
    .title {
      color: #fff;
      line-height: 40px;
      text-align: center;
    }
    .el-form-item {
      width: 33%;
      min-height: 52px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      border-top: 1px solid rgba(59, 104, 169, 0.48);
      border-right: 1px solid rgba(59, 104, 169, 0.48);

      &.borderbot {
        border-bottom: 1px solid #5e5e5f;
      }
      &.cityItem {
        .el-form-item__content {
          .el-select {
            height: 32px;
            width: 120px;
            .el-input {
              width: 100%;
              border-radius: 0;
              border: 1px solid rgba(151, 151, 151, 0.29);
              line-height: 28px;
            }
          }
        }
      }
      &.docItemMain {
        min-height: 108px;
        position: relative;
        .el-form-item__label {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .el-form-item__content {
          .model_upload {
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .el-form-item {
          border: none;
        }
        .el-form-item__label {
          height: 100%;
          position: absolute;
        }
        .el-form-item__content {
          line-height: 0;
          position: relative;
          left: 195px;
          width: calc(100% - 195px);
          background: #0a1842;
          & > div {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%;
          }
          .el-upload-list--picture-card {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;
            .el-upload-list__item {
              width: 80px;
              height: 60px;
              margin: 20px 0 20px 40px;
              border: none;
            }
          }
          .el-upload--picture-card {
            width: 80px;
            height: 60px;
            background: #21272b;
            border-radius: 2px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;
            i {
              color: #fff;
            }
          }
          div.hideUpload {
            .el-upload--picture-card {
              display: none;
            }
          }
        }
      }
      .el-form-item__label {
        min-width: 180px;
        height: 100%;
        background: #07183e;
        // border-radius: 0px 0px 0px 8px;
        padding: 0;
        text-align: center;
        line-height: 52px;
        &.requiredTip:before {
          content: "*";
          color: #f56c6c;
          margin-right: 4px;
        }
      }
      .el-upload-list__item-name {
        max-width: 150px;
      }
      .el-form-item__content {
        flex: 1;
        display: flex;
        align-items: center;
        height: 52px;
        .el-select {
          .el-input {
            width: 250px;
            height: 32px;
            border-radius: 0px;
            border: 1px solid rgba(151, 151, 151, 0.29);
            line-height: 28px;
            margin-left: 13px;
          }
        }
        .el-input {
          width: 100%;
          height: 52px;
          border: none;
          .el-input__inner {
            border: none;
            border-radius: 0px;
            // text-align: center;
          }
          .el-input__icon {
            line-height: 32px;
          }
        }
        .el-checkbox__inner {
          width: 20px;
          height: 20px;
          &:after {
            height: 9px;
            left: 6px;
            width: 5px;
          }
        }
        .el-form-item__error {
          // left: 48px;
        }
        .tips {
          color: #f0f0f0;
          padding-left: 30px;
        }
      }
    }
    .el-input__suffix-inner {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .el-input__prefix {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .el-upload {
      margin-right: 30px;
    }
  }
}
.mT70 {
  margin-top: 70px;
}
.model_upload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .tip-text {
    color: #999;
    padding: 0 15px;
    font-size: 15px;
    letter-spacing: 0px;
  }
  .name-text {
    padding: 0 15px;
    font-size: 15px;
    letter-spacing: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  .model_upload_title {
    color: #fff;
    opacity: 0.4;
    margin-left: 15px;
  }
  .upload-demo-model {
    height: 34px;
    margin-left: 8px;
    display: flex;
  }
  .upload-btn-warp {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2667db59;
    border: 1px solid #2667db;
    border-radius: 3px;
    width: 80px;
    height: 34px;

    .upload-img {
      width: 20px;
      height: 20px;
    }
    .shuru-img {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }

  v-deep.el-upload-list__item-name {
    max-width: 150px;
  }
}
.restBtn {
  width: 129px;
  height: 40px;
  background: #1e2529;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-left: 60px;
  &:hover {
    background: #1e2529;
  }
}

::v-deep .my-dialog div.el-dialog {
  width: 625px !important;
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__header {
    border-bottom: none;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
    }
  }
  .el-dialog__body {
    padding: 0 15px 20px 15px;
    color: #fff;
    .report-wrapper {
      border-top: 1px solid rgba(204, 218, 255, 0.3);
      .title {
        font-size: 16px;
        padding: 10px 0;
        text-align: center;
      }
      .report-table {
        width: 100%;
        font-size: 12px;
        border-collapse: collapse;
        tr:nth-child(odd) {
          background: #0c265a;
        }
        th,
        td {
          height: 35px;
          font-weight: 400;
          border: 1px solid rgba(204, 218, 255, 0.3);
          padding: 6px 3px;
          text-align: left;
          vertical-align: middle;
          box-sizing: border-box;
        }
        th {
          text-align: center;
        }
        td {
          text-align: left;
        }
        .center-tr td {
          text-align: center;
        }
        .center-tr td.first-td {
          border-left: 0;
        }
        .required-text {
          color: red;
        }
        .common-collapse {
          tr:first-child td {
            border-top: 0;
          }
          tr:last-child td {
            border-bottom: 0;
          }
          .end-td {
            border-right: 0;
            /* padding: 0; */
          }
        }
        .collapse-div {
          max-height: 485px;
          overflow: auto;
        }
        .report-collapse-div {
          max-height: 305px;
          overflow: auto;
          .center-tr td {
            border-right: 0;
            border-bottom: 0;
          }
        }
        .my-scroll-table .center-tr td {
          border-left: 0;
          border-bottom: 0;
        }
      }
      .chart-wrapper {
        margin-top: 10px;
        padding-bottom: 10px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        .title {
          font-size: 14px;
        }
        .measure-img-wrapper {
          height: 40px;
          margin: 5px 0;
        }
        #charts {
          height: 200px;
          padding-right: 5px;
        }
      }
      /* .description-wrapper {
                height: 100px;
                .title {
                    font-size: 12px;
                    text-align: left;
                    padding: 5px 0;
                }
                .content {
                    width: 1100px;
                    font-size: 20px;
                    transform: scale(0.5);
                    transform-origin: 0 0;
                    color: rgba(255,255,255,0.86);
                }
            } */
      .btn-footer {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        text-align: center;
        .el-button {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-weight: 500;
        }
        .import-btn {
          width: 160px;
          margin-right: 20px;
        }
        .cancel-btn {
          margin-left: 20px;
          background-color: rgba(255, 0, 0, 0);
          color: #fff;
          border: 1px solid #2667db;
          border-radius: 20px;
        }
      }
    }
  }
  .el-input {
    width: 100%;
  }
  .el-input__inner {
    border-radius: 0;
  }
}
::v-deep .el-form-item__error {
  right: 5px !important;
  top: auto;
  left: auto;
}
::v-deep .el-form-item__label {
  // border-top: 1px solid rgba(59, 104, 169, 0.48);
  border-right: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .el-form-item__content {
  background-color: #0a1a42;
  // border-top: 1px solid rgba(59, 104, 169, 0.48);
  // border-right: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .selectItem .el-input .el-input__inner {
  background: #081b42 !important;
}
::v-deep .el-input .el-input__inner {
  background-color: #0a1a42 !important;
}

::v-deep .jungongTime .el-input__prefix {
  left: auto;
  right: 20px;
}
::v-deep .jungongTime .el-input__inner {
  padding-left: 15px;
}

::v-deep .el-form-item__content .el-cascader {
  width: 262px;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding-left: 13px;
}
::v-deep .el-form-item__content .el-cascader .el-input {
  border: 1px solid rgba(151, 151, 151, 0.29) !important;
  height: 32px !important;
  line-height: 32px;
  position: relative;
}
::v-deep .el-form-item__content .el-cascader .el-cascader__tags {
  width: 200px;
  left: 15px;
}
::v-deep .el-form-item__content .el-cascader .el-input .el-input__inner {
  height: 28px !important;
}

::v-deep .border-item {
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
  position: relative;
  top: 1px;
}

::v-deep .img-box .el-form-item {
  width: 248px !important;
  display: inline-block !important;
  .upload-demo {
    margin: 0;
    padding-left: 28px;
  }
}
::v-deep .img-box .el-form-item__error {
  width: 102px !important;
  position: absolute;
  right: 0px !important;
  display: flex;
  justify-content: flex-end;
}

::v-deep .danwei-item {
  position: relative;
}
::v-deep .danwei-item .el-form-item {
  width: 100% !important;
}

::v-deep .danwei-item .el-form-item__content {
  width: 100%;
}

::v-deep .danwei-item .el-form-item__error {
  position: absolute;
  right: 5px !important;
}
::v-deep .system-item .el-form-item__content {
  width: 200px !important;
}
::v-deep .system-item .model_upload {
  width: 200px !important;
}
.formMain-title {
  display: flex;
  align-items: center;
  padding: 16px 0 23px 16px;
}
.formMain-content {
  margin: 0 30px 20px 43px;
  border-left: 1px solid rgba(59, 104, 169, 0.48);
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}
</style>
